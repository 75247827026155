<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div>
          <div class="invite-friends">
            <div class="invite-title">邀请好友注册，立享佣金奖励</div>
            <div class="invite-link">我发现一个电商人必备的礼品代发网站，{{url}}</div>
            <div class="invite-btn"><el-button type="primary" size="small" v-clipboard:copy="url" v-clipboard:success="onCopy" v-clipboard:error="onError">复制网址，邀请好友注册</el-button></div>
            <div class="invite-total">已成功邀请 <span>0人</span></div>
            <div class="invite-step">
              <div class="invite_step_item one">
                <img src="~assets/images/mall/people1.png">
                <span>①分享链接给好友</span>
              </div>
              <div class="jiantou"></div>
              <div class="invite_step_item two">
                <img src="~assets/images/mall/people2.png">
                <span>②好友注册，并下单成功</span>
              </div>
              <div class="jiantou"></div>
              <div class="invite_step_item three">
                <img src="~assets/images/mall/people3.png">
                <span>③您将获得佣金奖励</span>
              </div>
              <span class="computed">佣金计算方式=好友支付订单包裹数 * 0.05元/单</span>
            </div>
          </div>
          <div class="invitetable_wrapper">
            <div class="menu">
              <el-input v-model="mobile" placeholder="手机号"></el-input>
              <el-button @click="clickQuery" type="primary">查询</el-button>
              <el-button @click="clickReset">重置</el-button>
            </div>
            <div class="table">
              <el-table :data="friends.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}">
                <el-table-column label="序号">
                  <template slot-scope="scope"><span>{{scope.$index + (currentPage - 1) * pageSize + 1}}</span></template>
                </el-table-column>
                <el-table-column prop="user_id" label="用户ID"></el-table-column>
                <el-table-column prop="username" label="用户名"></el-table-column>
                <el-table-column label="推广码"></el-table-column>
                <el-table-column prop="user_type" label="用户类型"></el-table-column>
                <el-table-column prop="createtime" label="创建时间"></el-table-column>
                <el-table-column prop="updatetime" label="修改时间"></el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="pagination">
                <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-size="pageSize"
                  layout="prev, pager, next"
                  :total="friends.length">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { inviteHttp, selectAll, inviteSelect } from 'network/api'

export default {
  inject: ['mainReload'],
  components: {
    AppMain
  },
  data () {
    return {
      url: '',
      mobile: '',
      friends: [],
      currentPage: 1,
      pageSize: 15
    }
  },
  created () {
    // 获取邀请ID
    inviteHttp({ appkey: this.$appkey }).then(res => {
      this.url = this.$url + '/register?user_id=' + res.data
    })
    // 初始化全部邀请信息
    this.getSelectAll()
  },
  methods: {
    // 分页改变
    handleCurrentChange (val) {
      this.currentPage = val
    },
    // 邀请的全部用户信息请求
    getSelectAll () {
      selectAll({ appkey: this.$appkey }).then(res => {
        this.friends = res.data
      })
    },
    // 邀请用户信息筛选请求
    getInviteSelect () {
      inviteSelect({
        mobile: this.mobile,
        appkey: this.$appkey
      }).then(res => {
        if (res.code === 1) {
          if (Array.isArray(res.data)) {
            this.friends = res.data
          } else {
            this.friends = []
            this.friends.push(res.data)
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 复制成功时的回调函数
    onCopy () {
      this.$message.success('复制成功')
    },
    // 复制失败时的回调函数
    onError () {
      this.$message.error('复制失败')
    },
    // 查询
    clickQuery () {
      this.getInviteSelect()
    },
    // 重置
    clickReset () {
      this.mainReload()
    }
  }
}
</script>

<style scoped src="styles/views/account/inviteFriends.css">
</style>>

<style>
.menu .el-input__inner {
  width: 240px;
  margin-right: 12px;
}
</style>
